import React, { useState } from 'react';

import styles from './ContactForm.module.css';

export default function ContactForm({
  name,
  email,
  message,
  setName,
  setEmail,
  setMessage,
}) {
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const handleChangeValue = (event) => {
    if (event.target.id === 'name') {
      setName(event.target.value);
      if (nameError && event.target.value) setNameError(false);
    } else if (event.target.id === 'email') {
      setEmail(event.target.value);
      if (emailError && event.target.value) setEmailError(false);
    } else if (event.target.id === 'message') {
      setMessage(event.target.value);
      if (messageError && event.target.value) setMessageError(false);
    }
  };

  const handleClick = () => {
    if (name) setNameError(false);
    else setNameError(true);

    if (email) setEmailError(false);
    else setEmailError(true);

    if (message) setMessageError(false);
    else setMessageError(true);
  };

  return (
    <>
      <p className={styles.formTitle}>Contact Us</p>
      <div className="mb-2 sm:mb-6">
        <label className={styles.formLabel} htmlFor="name">
          Name
        </label>
        <input
          className={`${
            nameError ? styles.errorInput : styles.formInput
          } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
          id="name"
          type="text"
          value={name}
          onChange={handleChangeValue}
        />
        {nameError ? (
          <p className="text-red-500 text-xs italic">
            Please fill out this field.
          </p>
        ) : null}
      </div>
      <div className="mb-2 sm:mb-6">
        <label className={styles.formLabel} htmlFor="email">
          Email
        </label>
        <input
          className={`${
            emailError ? styles.errorInput : styles.formInput
          } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
          id="email"
          type="email"
          value={email}
          onChange={handleChangeValue}
        />
        {emailError ? (
          <p className="text-red-500 text-xs italic">
            Please fill out this field.
          </p>
        ) : null}
      </div>
      <div className="mb-4 sm:mb-8">
        <label className={styles.formLabel} htmlFor="message">
          Message
        </label>
        <textarea
          className={`${
            messageError ? styles.errorTextArea : styles.formTextArea
          } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
          id="message"
          type="text"
          value={message}
          onChange={handleChangeValue}
        />
        {messageError ? (
          <p className="text-red-500 text-xs italic">
            Please fill out this field.
          </p>
        ) : null}
      </div>
      <div className="grid grid-cols-2">
        <button
          className={`${styles.formButton} text-white focus:outline-none focus:shadow-outline`}
          type="submit"
          onClick={handleClick}
        >
          Send
        </button>
        <div className="flex justify-end items-center">
          <a
            href="https://www.instagram.com/nubeverage"
            target="_blank"
            rel="noreferrer"
            className="transition-all w-[26px] max-w-[26px] h-[26px] max-h-[26px] sm:w-10 sm:max-w-[40px] sm:h-10 sm:max-h-[40px] rounded-lg hover:shadow-lg focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none mr-[13px] sm:mr-4 md:mr-5"
          >
            <img src="/icons/instagram.svg" alt="instagram" />
          </a>
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noreferrer"
            className="transition-all w-[26px] max-w-[26px] h-[26px] max-h-[26px] sm:w-10 sm:max-w-[40px] sm:h-10 sm:max-h-[40px] rounded-lg hover:shadow-lg focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none mr-[13px] sm:mr-4 md:mr-5"
          >
            <img src="/icons/facebook.svg" alt="facebook" />
          </a>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noreferrer"
            className="transition-all w-[26px] max-w-[26px] h-[26px] max-h-[26px] sm:w-10 sm:max-w-[40px] sm:h-10 sm:max-h-[40px] rounded-lg hover:shadow-lg focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
          >
            <img src="/icons/twitter.svg" alt="twitter" />
          </a>
        </div>
      </div>
      <div className="flex">
        <p className={styles.contactEmail}>
          Email: <span className="underline">support@nubeverage.ca</span>
        </p>
        <p className={styles.contactTel}>Tel: 123-456-7890</p>
      </div>
    </>
  );
}
